<template>
	<div class="add_update_role">
		<jy-dialog type="table" :title="title" :visible.sync="dialogVisible">
			<div>
				<el-form :model="diaform" ref="dialogForm" :rules="formRules" :disabled="isCheck" :label-width="formLabelWidth">
					<el-form-item label="线路组织:" prop="routeId">
						<div>
							<el-input
								@focus="selectInstitutions"
								readonly
								placeholder="请选择"
								v-model="diaform.routeName"
								:disabled="type == 'update'"
							></el-input>
						</div>
					</el-form-item>
					<el-form-item label="运行方向:" prop="goType">
						<el-select v-model="diaform.goType" placeholder="请选择" :disabled="isDis">
							<el-option v-for="item in upDownList" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="备注:" prop="remark">
						<el-input v-model="diaform.remark" type="textarea" :rows="4" placeholder="请输入内容"></el-input>
					</el-form-item>
					<el-form-item label="站点:" prop="sites">
						<div>
							<el-row>
								<!-- <el-col :span="20">
                                    <el-select v-model="selectedIdsList" multiple placeholder disabled class="select_view">
                                        <el-option disabled v-for="item in selectedList" :key="item.siteId" :label="item.name" :value="item.siteId">
                                            <span v-text="item.name||''"></span>
                                            <span v-text="item.order||''"></span>
                                        </el-option>
                                    </el-select>
                                </el-col> -->
								<!-- <el-col :span="4" class="button_sites"> -->
								<el-button type="primary" size="small" @click="siteSelect">设置</el-button>
								<!-- </el-col> -->
							</el-row>
						</div>
					</el-form-item>
					<!-- <el-form-item label="状态:" prop="state" :label-width="formLabelWidth">
                        <el-radio v-model="diaform.state" :label="1">启用</el-radio>
                        <el-radio v-model="diaform.state" :label="0">禁用</el-radio>
                    </el-form-item>-->
				</el-form>
				<jy-table :data="diaform.sites" max-height="200">
					<jy-table-column prop="order" label="站台编号" width="100"></jy-table-column>
					<jy-table-column prop="name" label="站名" min-width="100"></jy-table-column>
					<jy-table-column prop="speedLimit" label="限速（km/h）" min-width="120">
						<template slot-scope="scope">
							<el-input v-model="scope.row.speedLimit" placeholder="请输入"></el-input>
						</template>
					</jy-table-column>
					<jy-table-column prop="reportDistance" label="站前里程（m）" min-width="120">
						<template slot-scope="scope">
							<el-input v-model="scope.row.reportDistance" placeholder="请输入"></el-input>
						</template>
					</jy-table-column>
					<jy-table-column prop="siteCategoryName" label="是否大站" min-width="120"></jy-table-column>
					<jy-table-column prop="playOutside" label="外音播报" min-width="120">
						<template slot-scope="scope">
							<el-select v-model="scope.row.playOutside" placeholder="请选择">
								<el-option
									v-for="item in accountStatusList"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								></el-option>
							</el-select>
						</template>
					</jy-table-column>
					<jy-table-column prop="audioId" label="报站音频" min-width="120">
						<template slot-scope="scope">
							<el-select v-model="scope.row.audioId" placeholder="请选择">
								<el-option v-for="item in audiolist[scope.$index]" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</template>
					</jy-table-column>
				</jy-table>
			</div>
			<div slot="footer" class="dialog-footer">
				<div v-if="!isCheck">
					<el-button @click="cancel">取 消</el-button>
					<el-button type="primary" @click="save('dialogForm')">确 定</el-button>
				</div>
			</div>
		</jy-dialog>
		<site-select ref="siteSelectDialog" @updateSite="onSite"></site-select>
		<institutions-tree
			ref="institutions"
			@addInstitutions="addInstitutions"
			url="/waybillOperate/getTree"
			title="选择线路组织"
			:defaultProps="props"
		></institutions-tree>
	</div>
</template>
<script type="text/javascript">
const formRules = {
	routeId: { required: true, message: "请选取线路", trigger: "change" },
	// goType: { required: true, message: '请选择类型', trigger: 'change' },
	sites: { required: true, message: "请选取站点", trigger: "change" }
};
import siteSelect from "@/components/pages/admin/resourcesAllocation/lineSite/siteSelect";
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
export default {
	data() {
		return {
			type: "",
			isCheck: false,
			isUpdate: false,
			title: "",
			siteTypeList: [],
			updowmnDisabled: false,
			upDownList: [
				{
					value: 1,
					label: "上行"
				},
				{
					value: 2,
					label: "下行"
				},
				{
					value: 3,
					label: "环形"
				}
			],
			zdTypeList: [],
			props: {
				children: "children",
				label: "text",
				value: "id",
				// multiple: true,
				checkStrictly: true
			},
			zzstatusList: [
				{
					label: "快速充电站",
					value: 1
				},
				{
					label: "一般充电站",
					value: 2
				}
			],
			diaform: {
				remark: "",
				routeId: "",
				routeName: "",
				siteIds: "",
				sites: [
					// {
					//     order: '',
					//     name: '',
					//     speedLimit: '',
					//     reportDistance: '',
					//     siteCategoryName: '',
					//     playOutside: '',
					//     audioId: '',
					// },
				],
				goType: "",
				state: 1,
				routeType: ""
			},
			cityId: "",
			city: "",
			formRules,
			selectedList: [],
			selectedIdsList: [],
			dialogVisible: false,
			formLabelWidth: "100px",
			isDis: false,
			accountStatusList: [
				{
					value: "1",
					label: "是"
				},
				{
					value: "0",
					label: "否"
				}
			],
			audiolist: []
		};
	},
	components: {
		siteSelect,
		institutionsTree
	},
	activated() {
		this.getsiteTypeList();
		this.getzdTypeList();
	},
	created() {},
	watch: {
		type: function (val) {
			let t = "";
			let isCheck = false;
			let isUpdate = false;
			switch (val) {
				case "add":
					t = "新增线路站点";
					break;
				case "update":
					t = "修改线路站点";
					isUpdate = true;
					break;
				case "view":
					t = "查看线路站点";
					isCheck = true;
					break;
				default:
					t = "新增线路站点";
			}
			this.isCheck = isCheck;
			this.isUpdate = isUpdate;
			this.title = t;
		},
		dialogVisible(newview) {
			if (!newview) {
				this.resetForm("dialogForm");
				this.diaform = {
					remark: "",
					routeId: "",
					routeName: "",
					siteIds: "",
					sites: [],
					goType: "",
					state: 1,
					routeType: ""
				};
				this.isDis = false;
				this.selectedIdsList = [];
				this.selectedList = [];
				this.audiolist = [];
				this.cityId = "";
				this.city = "";
			}
		},
		"diaform.xianlutype": function (newvalue) {
			if (parseInt(newvalue) == 2) {
				this.diaform.updown = "";
				this.updowmnDisabled = true;
			} else {
				this.updowmnDisabled = false;
			}
		},
		"diaform.routeType"(val) {
			if (val == "1") {
				this.diaform.goType = "";
				this.isDis = true;
			} else {
				this.isDis = false;
			}
		},
		selectedIdsList: function (val) {
			this.diaform.siteIds = val;
		},
		"diaform.sites"(val) {
			if (val.length) {
				this.audiolist = [];
				val.forEach((item, key) => {
					this.getAudiolist(item.siteId, key);
				});
			}
		}
	},
	methods: {
		// 组织
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		addInstitutions(data) {
			console.log(data);
			this.diaform.routeId = data.id;
			this.diaform.routeName = data.text;
			this.diaform.routeType = data.standby;
			this.cityId = data.obj.cityId;
			this.city = data.obj.belongCity;
		},
		// 线路类别
		getsiteTypeList() {
			//   let url = '/system/org/queryOrgTree'
			//   this.$http.post(url).then(({ detail }) => {
			//     console.log(detail)
			//     this.siteTypeList = detail
			//   })
			this.siteTypeList = [
				{
					value: 1,
					label: "上下行"
				},
				{
					value: 2,
					label: "环形"
				}
			];
		},
		// 站点类别
		getzdTypeList() {
			// let url = '/system/org/queryOrgTree'
			// this.$http.post(url).then(({ detail }) => {
			//   console.log(detail)
			//   this.zdTypeList = detail
			// })
			this.zdTypeList = [
				{
					value: 1,
					label: "首末站"
				},
				{
					value: 2,
					label: "中途站点"
				}
			];
		},
		init(option, type) {
			this.type = type ? type : "add";
			this.dialogVisible = true;
			if (option) {
				this.$nextTick(() => {
					if (this.type == "add") {
						let addOption = JSON.parse(JSON.stringify(option));
						this.diaform.routeId = addOption.routeId;
						this.diaform.routeName = addOption.routeName;
					} else {
						let updateOption = JSON.parse(JSON.stringify(option));
						this.diaform = {
							...this.diaform,
							...updateOption
						};
						this.diaform.goType = parseInt(updateOption.goType);
						option.sites.forEach(one => {
							one.name = one.siteName;
						});
						this.selectedList = option.sites;
						this.setSelectIdsList();
						if (updateOption.state == "启用") {
							this.diaform.state = 1;
						} else if (updateOption.state == "禁用") {
							this.diaform.state = 0;
						}
					}
				});
			}
		},
		setSelectIdsList(list) {
			list = list || this.selectedList;
			this.selectedIdsList = list.map(one => {
				return one.siteId;
			});
		},
		//报站音频
		getAudiolist(siteId, key) {
			let url = "/BaseInfoSiteAudio/list";
			let option = {
				siteId: siteId
			};
			this.$http.post(url, option).then(({ detail }) => {
				this.$set(this.audiolist, key, detail);
			});
		},
		// 站点选择
		siteSelect() {
			if (!this.diaform.routeId) {
				this.$message({
					type: "warning",
					message: "请先选择线路组织"
				});
				return false;
			}
			this.$refs.siteSelectDialog.init(this.selectedIdsList, this.selectedList, this.cityId, this.city);
		},
		onSite(selectedIdsList, selectedList) {
			// 选择的站点显示

			// this.diaform.siteName = siteArr.name
			// this.diaform.siteId = siteArr.siteId

			this.selectedList = selectedList;
			this.selectedIdsList = selectedIdsList;
			this.diaform.sites = selectedList;
			console.log(this.selectedList);
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		cancel() {
			this.dialogVisible = false;
			console.log("cancel");
		},
		save() {
			let option = {
				...this.diaform,
				siteIds: this.selectedIdsList
			};
			let url = "/routeRelSite/add";

			this.$refs.dialogForm.validate(valid => {
				if (valid) {
					this.$http.post(url, option).then(res => {
						this.$message({
							type: "success",
							message: res.msg
						});
						this.dialogVisible = false;
						this.$emit("updateList");
					});
				}
			});
		}
	}
};
</script>
<style type="text/css" lang="scss" scoped="add_update_role">
.select_view {
	width: 100%;
}

.button_sites {
	text-align: right;
}
</style>
