<template>
	<div class="car_select">
		<jy-dialog
			type="table"
			:title="title"
			:visible.sync="dialogVisible"
			custom-class="dialog_table"
			:width="global.dialogTabelWidth"
			append-to-body
		>
			<el-button type="primary" @click="batchConfig">批量配置</el-button>
			<!--  @row-click="rowClick" -->
			<jy-table max-height="595" :data="sites">
				<jy-table-column prop="order" label="站台编号" width="100"></jy-table-column>
				<jy-table-column prop="name" label="站名"></jy-table-column>
				<jy-table-column label="进站广告">
					<template slot-scope="scope">
						<el-select v-model="scope.row.inAdId" placeholder="请选择">
							<el-option v-for="item in adsList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</template>
				</jy-table-column>
				<jy-table-column label="出站广告">
					<template slot-scope="scope">
						<el-select v-model="scope.row.outAdId" placeholder="请选择">
							<el-option v-for="item in adsList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</template>
				</jy-table-column>
				<jy-table-column label="进站提示">
					<template slot-scope="scope">
						<el-select v-model="scope.row.inTipId" placeholder="请选择">
							<el-option v-for="item in tipsList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</template>
				</jy-table-column>
				<jy-table-column label="出站提示">
					<template slot-scope="scope">
						<el-select v-model="scope.row.outTipId" placeholder="请选择">
							<el-option v-for="item in tipsList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</template>
				</jy-table-column>
			</jy-table>
			<!-- <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination> -->
			<div slot="footer" class="dialog-footer">
				<div>
					<el-button @click="resetForm">重 置</el-button>
					<el-button type="primary" @click="save">确 定</el-button>
				</div>
			</div>
		</jy-dialog>
		<jy-dialog
			type="form"
			title="批量配置"
			:visible.sync="configVisible"
			custom-class="dialog_form"
			:width="global.dialogWidth"
			append-to-body
		>
			<el-form :model="diaform" ref="dialogForm" :label-width="formLabelWidth">
				<el-form-item label="进站广告:" prop="inAdId">
					<el-select v-model="diaform.inAdId" placeholder="请选择">
						<el-option v-for="item in adsList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="出站广告:" prop="outAdId">
					<el-select v-model="diaform.outAdId" placeholder="请选择">
						<el-option v-for="item in adsList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="进站提示:" prop="inTipId">
					<el-select v-model="diaform.inTipId" placeholder="请选择">
						<el-option v-for="item in tipsList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="出站提示:" prop="outTipId">
					<el-select v-model="diaform.outTipId" placeholder="请选择">
						<el-option v-for="item in tipsList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<div>
					<el-button @click="cancel">取 消</el-button>
					<el-button type="primary" @click="saveConfig('dialogForm')">确 定</el-button>
				</div>
			</div>
		</jy-dialog>
	</div>
</template>
<script type="text/javascript">
export default {
	data() {
		return {
			pageSize: 10,
			total: 0,
			pageIndex: 1,
			sites: [],
			routeId: "",
			dialogVisible: false,
			title: "广告提示配置",

			adsList: [],
			tipsList: [],
			configVisible: false,
			formLabelWidth: "100px",
			diaform: {
				inAdId: "",
				outAdId: "",
				inTipId: "",
				outTipId: ""
			}
		};
	},
	watch: {
		dialogVisible(newview) {
			if (!newview) {
				this.resetForm();
				// this.$refs['dialogForm'].resetFields()
			}
		},
		configVisible(newview) {
			if (!newview) {
				this.diaform = {
					inAdId: "",
					outAdId: "",
					inTipId: "",
					outTipId: ""
				};
			}
		}
	},
	activated() {},
	methods: {
		init(option) {
			this.$nextTick(() => {
				let form = JSON.parse(JSON.stringify(option));
				this.dialogVisible = true;
				this.sites = form.sites;
				this.routeId = form.routeId;
				this.getAdsList();
				this.getTipsList();
			});
		},
		getAdsList() {
			let url = "/BaseInfoAd/getAllocatedAds";
			let option = {
				routeId: this.routeId
			};
			this.$http.post(url, option).then(res => {
				this.adsList = res.detail;
			});
		},
		getTipsList() {
			let url = "/BaseInfoTip/getAllocatedTips";
			let option = {
				routeId: this.routeId
			};
			this.$http.post(url, option).then(res => {
				this.tipsList = res.detail;
			});
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			// this.getList()
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			// this.getList()
		},
		// 重置
		resetForm() {
			this.sites.forEach(item => {
				this.$set(item, "inAdId", "");
				this.$set(item, "outAdId", "");
				this.$set(item, "inTipId", "");
				this.$set(item, "outTipId", "");
			});
		},
		// 配置保存
		save() {
			console.log(this.sites);
			let url = "/routeRelSite/update";
			let option = this.sites;
			this.$http.post(url, option).then(res => {
				this.$message({
					type: "success",
					message: res.msg
				});
				this.dialogVisible = false;
				this.$emit("updateList");
			});
		},
		batchConfig() {
			this.configVisible = true;
		},
		saveConfig() {
			this.sites.forEach(item => {
				this.$set(item, "inAdId", this.diaform.inAdId);
				this.$set(item, "outAdId", this.diaform.outAdId);
				this.$set(item, "inTipId", this.diaform.inTipId);
				this.$set(item, "outTipId", this.diaform.outTipId);
			});
			this.$nextTick(() => {
				this.configVisible = false;
			});
		},
		cancel() {
			this.configVisible = false;
		}
	}
};
</script>
<style type="text/css" lang="scss" scoped="advertisePrompt">
.dialog-footer {
	margin-top: 20px;
}
</style>
